<script>
import layoutProfile from "@/views/layouts/layoutProfile";
import PaymentMethods from "@/components/Partials/PaymentMethods";
export default {
  name: "Payments",
  components: {
    layoutProfile,
    PaymentMethods
  }
}
</script>

<template>
  <layoutProfile>
    <PaymentMethods />
  </layoutProfile>
</template>

<style scoped lang="scss">

</style>