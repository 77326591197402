<script>
import {required} from "vuelidate/lib/validators";
import {paymentCardListComputed, paymentCardMethods, notificationMethods} from "@/state/helpers";
import Loader from "@/components/Partials/Loader.vue";
import CardImageSvg from "@/components/Svg/CardImageSvg.vue";

export default {
	name: "PaymentMethods",
	components: { Loader, CardImageSvg},
	data() {
		return {
			showModal: false,
			showDeleteModal: false,
			deleteCardId: false,
			submitted: false,
			cardNumber: "",
			cardExpiry: "",
			cvv: "",
			fields: ['type', 'number', 'expired', 'actions']
		}
	},
	validations: {
		cardNumber: {required},
		cardExpiry: {required},
		cvv: {required},
	},
	computed: {
		...paymentCardListComputed,
		notification() {
			return this.$store ? this.$store.state.notification : null;
		},
		notificationAutoCloseDuration() {
			return this.$store && this.$store.state.notification ? 5 : 0;
		},
		filteredPaymentCardList() {
			return this.paymentCardList.map((item) => {
				return {
					id: item.id,
					type: item.type,
					uuid: item.uuid,
					number: item.number,
					expired: item.exp_month + '/' + item.exp_year,
					has_subscription: item.has_subscription,
				}
			})
		}
	},
	methods: {
		...paymentCardMethods,
		...notificationMethods,
		detectCardType() {
			const type = document.getElementById('card-type');
			return type.getAttribute('data-card-brand');
		},
		async updateCardInfo() {
			this.submitted = true;
			this.$v.$touch();
			let cardExpDateYear = this.cardExpiry.split('/');
			const cardData = {
				number: this.cardNumber.replace(/\s/g, ''),
				exp_month: cardExpDateYear[0],
				exp_year: cardExpDateYear[1],
				cvv: this.cvv,
				type: this.detectCardType()
			}
			if (this.$v.$invalid) {
				return
			} else {
				await this.$store.dispatch("paymentCardList/addCreditCard", cardData);
				if(this.notification.type !== 'danger') {
					this.showModal = false;
				}
			}
		},
		onDeleteCard(id) {
			this.showDeleteModal = true
			this.deleteCardId = id
		},
		deleteCard() {
			this.$store.dispatch("paymentCardList/deleteCreditCard", this.deleteCardId)
			this.showDeleteModal = false
		}
	},
	mounted() {
		this.$store.dispatch("paymentCardList/getPaymentCardsList")
	}
}
</script>

<template>
	<div class="payment-method-outer">
		<div class="px-4 pt-4">
			<div class="d-flex justify-content-between align-items-center">
				<h4 class="mb-0">Payment Method</h4>
				<button
					style="max-width: 200px;"
					class="btn btn-primary btn-block"
					@click="showModal = true"
				>
					<i class="ri-add-line"></i>
					Add Payment
				</button>
			</div>
		</div>
		<div class="p-4 user-profile-desc">
			<div class="card">
				<Loader v-if="!filteredPaymentCardList"/>
				<div v-else class="card-body p-4 filter-table">
					<b-table :items="filteredPaymentCardList" :fields="fields" responsive="sm" class="text-center">
						<template v-slot:cell(type)="{ item }">
							<CardImageSvg class="card-img-svg" :type="item.type"/>
						</template>
						<template v-slot:cell(actions)="{ item }">
							<div v-if="!item.has_subscription" @click="onDeleteCard(item.uuid)" style="cursor: pointer">
								<i class="ri-delete-bin-6-line"></i>
							</div>
						</template>
					</b-table>
				</div>
			</div>
			<b-modal
				v-model="showModal"
				title="Add Payment"
				hide-footer
			>
				<b-alert
					:variant="notification.type"
					class="mt-3"
					v-if="notification.message"
					:show="notificationAutoCloseDuration"
					dismissible
				>{{ notification.message }}
				</b-alert>
				<form @submit.prevent="updateCardInfo">
					<div class="form-group">
						<label>{{ $t('checkout.form.card') }}</label>
						<div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
							<div class="input-group-prepend">
                        <span class="input-group-text border-light text-muted">
                          <i class="ri-bank-card-fill"></i>
                        </span>
							</div>
							<input
								v-model="cardNumber"
								id="card-type"
								class="form-control bg-soft-light border-light"
								:class="{ 'is-invalid': submitted && $v.cardNumber.$error }"
								:placeholder="$t('checkout.form.card')"
								v-cardformat:formatCardNumber
							/>
							<div v-if="submitted && $v.cardNumber.$error" class="invalid-feedback">
                        <span
							v-if="!$v.cardNumber.required"
						>Error. </span>
								<span v-if="!$v.cardNumber.cardNumber">Please provide valid card number</span>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label>{{ $t('checkout.form.expiryDate') }}</label>
						<div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
							<input
								v-model="cardExpiry"
								class="form-control bg-soft-light border-light"
								:class="{ 'is-invalid': submitted && $v.cardExpiry.$error }"
								:placeholder="$t('checkout.form.expiryDate')"
								v-cardformat:formatCardExpiry
							/>
							<div v-if="submitted && $v.cardExpiry.$error" class="invalid-feedback">
                        <span
							v-if="!$v.cardExpiry.required"
						>Error. </span>
								<span v-if="!$v.cardExpiry.cardExpiry">Please provide valid card exp. date</span>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label>{{ $t('checkout.form.cvv') }}</label>
						<div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
							<input
								v-model="cvv"
								class="form-control bg-soft-light border-light"
								:class="{ 'is-invalid': submitted && $v.cvv.$error }"
								:placeholder="$t('checkout.form.cvv')"
								v-cardformat:formatCardCVC
							/>
							<div v-if="submitted && $v.cvv.$error" class="invalid-feedback">
                        <span
							v-if="!$v.cvv.required"
						>Error. </span>
								<span v-if="!$v.cvv.cvv">Please provide valid cvv</span>
							</div>
						</div>
					</div>
					<div class="text-right pt-5 mt-3">
						<b-button variant="link" @click="showModal = false">{{
								$t("chat.tabs.settings.modal.form.button.close")
							}}
						</b-button>
						<b-button type="submit" variant="primary" class="ml-1">{{
								$t("chat.tabs.settings.modal.form.button.save")
							}}
						</b-button>
					</div>
				</form>
			</b-modal>
			<b-modal
				v-model="showDeleteModal"
				title="Are you sure you want to delete your credit card?"
				hide-footer
			>
				<div>
					<p>Just a friendly heads-up: this action will cancel all subscriptions associated with it.
						If you do, you'll need to purchase your subscriptions again.</p>
				</div>
				<div class="text-right pt-5 mt-3">
					<b-button variant="link" @click="showDeleteModal = false">
						Cancel
					</b-button>
					<b-button variant="primary" class="ml-1" @click="deleteCard()">
						Delete
					</b-button>
				</div>
			</b-modal>
		</div>
	</div>
</template>

<style lang="css" scoped>
.header-description{
	font-size: 16px;
	margin-bottom: 0;
	margin-top: 15px;
}
</style>