<script>
export default {
	name: "NavBar",
	data() {
		return {
			profileItems: [
				{
					id: 1,
					name: 'Profile',
					route: 'profile',
					icon: '<i class="ri-user-2-line"></i>'
				},
				{
					id: 2,
					name: 'Identity',
					route: 'identity',
					icon: '<i class="ri-information-line"></i>'
				},
				{
					id: 3,
					name: 'Payment Method',
					route: 'payments',
					icon: '<i class="ri-bank-card-line"></i>'
				},
				{
					id: 4,
					name: 'Membership',
					route: 'membership',
					icon: '<i class="ri-wallet-3-line"></i>'
				},
				{
					id: 5,
					name: 'Help',
					route: 'help',
					icon: '<i class="ri-question-line"></i>'
				}
			]
		}
	},
	computed: {
		currentRouteName() {
			return this.$route.name;
		}
	}
}
</script>

<template>
	<div id="profile-routes" class="d-flex">
		<router-link
			v-for="item in profileItems"
			:key="item.id"
			:to="item.route"
			class="nav-link"
			role="tab"
			:class="[currentRouteName === item.route ? 'active' : '']"
		>
			<span class="pr-2" v-html="item.icon"></span>{{ item.name }}
		</router-link>
	</div>
</template>