<script>
import NavBarProfile from "@/components/NavBarProfile.vue";
import NavBarLeft from "@/components/NavBarLeft.vue";
import {pixelsMethods} from "@/state/helpers";

export default {
	name: "layoutProfile",
	components: {
		NavBarLeft,
		NavBarProfile
	},
	methods: {
		...pixelsMethods,
	},
	mounted() {
		this.sendPixelEvent('pageview');
	}
}
</script>

<template>
	<div class="layout-wrapper d-lg-flex">
		<NavBarLeft/>
		<div class="w-100">
			<div class="px-4 pt-4">
				<div class="user-chat-nav">
					<NavBarProfile/>
				</div>
			</div>
			<slot name="default"/>
		</div>
	</div>
</template>

<style scoped lang="scss">

</style>